<template>
        <v-responsive>
               <v-data-table
                     dense
                     :headers="headers"
                     :items="data"
                     :search="search"
                     :class="{mobile: isMobile}"
                     :sort-by="['id']"
                     :sort-desc="[true]"
                     :itemsPerPage="50"
               >
                <template slot="item" slot-scope="props">
                        <tr v-if="!isMobile">
                                <td>#{{ props.item.id }}</td>
                                <td class="text-xs-left">{{ props.item.fullname }}</td>
                                <td class="text-xs-left">{{ props.item.username }}</td>
                                <td class="text-xs-left">{{ props.item.phoneNumber }}</td>
                                <td class="text-xs-left" v-if="props.item.role==1">Yönetici</td>
                                <td class="text-xs-left" v-else>Kullanıcı</td>
                                <td class="text-xs-left" v-if="props.item.status==1">Aktif</td>
                                <td class="text-xs-left" v-else>Pasif</td>
                                <td class="text-xs-left">{{ props.item.created }}</td>
                                <td class="text-xs-left">
                                        <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                        <v-icon color="primary" dark v-bind="attrs" v-on="on" :disabled="dialog" :loading="dialog" @click="UserEdit(props.item)">edit</v-icon></template>
                                                <span>Düzenle</span>
                                        </v-tooltip>
                                        <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                        <v-icon
                                                                color="primary"
                                                                dark
                                                                v-bind="attrs"
                                                                v-on="on"
                                                                :disabled="dialog"
                                                                :loading="dialog"
                                                                @click="UserRemove(props.item,data.indexOf(props.item))"
                                                        >
                                                                delete_forever
                                                        </v-icon>
                                                </template>
                                                <span>Sil</span>
                                        </v-tooltip>

                                </td>
                        </tr>
                        <tr v-else>
                                <td>
                                        <ul class="flex-content">
                                                <li class="flex-item" data-label="fullname">#{{ props.item.fullname }}</li>
                                                <li class="flex-item" data-label="username">{{ props.item.username }}</li>
                                                <li class="flex-item" data-label="phoneNumber">{{ props.item.phoneNumber }}</li>
                                                <li class="flex-item" data-label="role" v-if="props.item.role==1">Yönetici</li>
                                                <li class="flex-item" data-label="role" v-else>Kullanıcı</li>
                                                <li class="flex-item" data-label="status" v-if="props.item.status==1">Aktif</li>
                                                <li class="flex-item" data-label="status" v-else>Aktif</li>
                                                <li class="flex-item" data-label="Durum" v-if="time_ago(props.item.LastUpdate)">{{ time_ago(props.item.LastUpdate) }}</li>
                                                <li class="flex-item" data-label="Durum" style="color:red;" v-else>İşlem Yok</li>
                                                <li class="flex-item" data-label="created">{{ props.item.created }}</li>
                                                <li class="flex-item" data-label="İşlem">
                                                        <v-icon style="font-size:20px;color:green"
                                                                :disabled="dialog"
                                                                :loading="dialog"
                                                                @click="PlaceUpdate(props.item)">autorenew</v-icon>
                                                </li>
                                        </ul>
                                </td>
                        </tr>
                </template>
                </v-data-table>
        </v-responsive>
</template>

<script>
    export default {
        name: "Users",
           data() {
                   return {
                           PlaceData: [],
                           isMobile: false,
                           headers: [
                                   {
                                           text: '#Id',
                                           align: 'left',
                                           value: 'P_Id'
                                   },
                                   {
                                           text: 'İsim Soyisim',
                                           value: 'fullname'
                                   },
                                   {
                                           text: 'Kullanıcı adı',
                                           value: 'username'
                                   },
                                   {
                                           text: 'Telefon',
                                           value: 'phoneNumber'
                                   },
                                   {
                                           text: 'Yetki',
                                           value: 'role'
                                   },
                                   {
                                           text: 'Durum',
                                           value: 'status'
                                   },
                                   {
                                           text: 'Kayıt',
                                           value: 'created'
                                   },
                                   {
                                           text: 'İşlem',
                                           value: 'action'
                                   },
                           ],
                           kayiterror: null,
                           confirm: false,
                           removeUser: null,
                           removeIndex: null
                   }
           },
            methods: {
                    UserSave () {
                            this.$refs.form.validate()
                            this.$store.dispatch("CreateUserAPI",this.user)
                            this.kayit=false;
                            this.user = {
                                    fullname: null,
                                    username: null,
                                    password: null,
                                    phoneNumber: null,
                                    status: null,
                                    role: null,
                                    place_id:null
                            }

                    },
                    UserEdit(row){
                            this.user=[];
                            this.guncelle = true;
                            var us = row;
                            this.user = us;
                            if(row.role==1){
                                    this.user.role = this.roles[0].id;
                            }else{
                                    this.user.role = this.roles[1].id;
                            }
                            this.user.password=null
                            if(this.user.place_id!=null || this.user.place_id!==0){
                                    this.user.place_id = this.PlaceData.find((item) => item.P_Id==row.place_id).P_Id
                            }
                    },
                    UserRemove(row,index){
                            this.removeIndex = index;
                            this.removeUser = row;
                            this.confirm=true
                    },
                    UserDelete(){
                            this.dialog = true
                            this.$store.dispatch("UsersRemoveAPI",this.removeUser)
                            this.data.splice(this.removeIndex,1)
                            this.dialog = false
                            this.confirm = false
                    },
                    onResize() {
                            if (window.innerWidth < 769)
                                    this.isMobile = true;
                            else
                                    this.isMobile = false;
                    },
            },
            mounted() {
                    if(this.$store.state.isLogin) {
                            this.$store.state.PageTitle = "Kullanıcılar"
                            if (this.data.length < 1) {
                                    this.$store.dispatch("UserListUpdate")
                            }
                    }
            },
            computed:{
                    data:{
                            get(){
                                    return this.$store.state.users.data
                            },
                            set(val){
                                    this.$store.users.commit("setUsersData",val)
                            }
                    },
                    search(){
                            return this.$store.state.search;
                    }
            },

    }
</script>

<style scoped>

</style>